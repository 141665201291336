@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F0F5F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#tag-cloud .badge:nth-child(3n) {
  font-weight: bold;
  color: white;
}

.text-gray-custom {
  color: #718096;
}

.bg-green-custom {
  background-color: #63C47B;
}

.bg-gray-custom {
  background-color: #dadada;
}

.bg-blue-custom {
  background-color: #97D8DB;
  color: #fff;
}

.border-blue-custom {
  border-color: #97D8DB;
  color: #007AFF;
}